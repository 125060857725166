import { template as template_33e1fe2720924c40bd7ee0c8bee6c126 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_33e1fe2720924c40bd7ee0c8bee6c126(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
