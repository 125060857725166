import { template as template_a53c148c7b0447428fef3afa55a8dafe } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_a53c148c7b0447428fef3afa55a8dafe(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
