import { template as template_5a3266acb22d467a8d6fdc8f7494fb63 } from "@ember/template-compiler";
const WelcomeHeader = template_5a3266acb22d467a8d6fdc8f7494fb63(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
