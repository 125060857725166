import { template as template_347ea44c2af1451a868765adf0927a3b } from "@ember/template-compiler";
const FKControlMenuContainer = template_347ea44c2af1451a868765adf0927a3b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
